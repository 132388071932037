const authors = {
   "elliot.waterhouse@smart-plan.com": {
      name: "Elliot Waterhouse",
      title: "Chief Digital Officer",
      bio: "Lorem Ipsum Depsom",
      image_url: ""
   },
   "kojo.prempeh@switchboard-group.com": {
      name: "Kojo Prempeh",
      title: "Digital Marketing Intern",
      bio: "Test text",
      image_url: ""
   }
}

export default authors