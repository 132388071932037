import React from "react";
import { Link, graphql } from "gatsby"
import { Navigation } from "../components/global/nav"
import { Footer } from "../components/global/footer";
import authors from "/src/resources/author-directory";
import { FaCompass } from "react-icons/fa"
import "../styles.css"
import Breadcrumbs from "../components/global/breadcrumbs";
import SEO from "../components/global/seo";


const GuideTemplate = (props, { location, pageContext }) => {
   const page = props.data.contentfulGuide;
   const content = page.content.internal.content;
   const title = page.title;
   const title_split = title.split("-")
   const slug = page.slug;
   const metadescription = page.metaDescription;
   const image = page.image.file.url;


   // AUTHOR
   const author = page.author
   const author_name = authors[author].name;
   const date_update= (new Date(page.updatedAt)).toLocaleDateString("en-GB");

   const content_word_count = content.split(" ").length + 1;
   const reading_time = (content_word_count/250).toFixed(0)


   const printInternalNavigation = () => {
      const string = page.navigation.internal.content;
      const json = JSON.parse(string)
      const array = Object.entries(json)

      let jsx = [];

      for (let i = 0; i < array.length; i++) {
         const el = array[i];
         const keyVAl = Object.entries(el[1])
         
         jsx.push(
            <a key={i} href={`#${keyVAl[0][1]}`}><li>{keyVAl[0][0]}</li></a>
         )
      }

      return jsx;
   }

   const ArtDesc = () => {
      const description = page.article_desc.internal.content;
      return {__html: description}
   }

   const bodyContent = () => {
      return {__html: content}
   }
   

   
   return (
      <React.Fragment>
         <SEO
            title={title}
            slug={slug}
            metadescription={metadescription}
            image={`https:${image}`}
            titleTemplate="%s - Smart Plan"
         />
         <Navigation />
         <div className="standard-title-container default">
            <div className="standard-title-inner-container body-inner-width">
               <div className="standard-title-inner-content">
                  <h1>{title_split[0]}<br/>
                  <span id="title_part2">{title_split[1]}</span></h1>
                  
                  <span dangerouslySetInnerHTML={ArtDesc()}></span>
                  
                  
               </div>
            </div>
         </div>

         <div className="standard-container">
            <div className="standard-inner-container body-inner-width">
               <div className="standard-content-container">
                  {/* CONTENT */}
               
                     <div className="content-column">
                     <Breadcrumbs
                        location={props.data.contentfulGuide.slug}
                     />
                        {/* ARTICLE DETAILS */}
                        <div className="article-details-container">
                           <p className="article-detail author-name">Written by <span>{author_name}</span></p>
                           <p className="article-detail article-last-updated">Last updated - <span>{date_update}</span></p>
                           <p className="article-detail article-reading-time">Estimated reading time - <span>{reading_time} minutes</span></p>
                        </div>

                        {/* IMAGE */}
                        <div 
                           style={{backgroundImage: `url(http:${props.data.contentfulGuide.image.file.url})`}}
                           className="article-thumbnail"
                        />

                     
                        {/* BODY */}
                        <div dangerouslySetInnerHTML={bodyContent()}>
                        </div>
                     </div>

                  {/* SIDEBAR */}
                  <div className="navigation-column">
                     <div className="navigation-entries-container">
                        <div class="sidebar-cta-container">
                           <h3>Speak to an engineer</h3>
                           <p>Call Smart Plan and speak to our expert engineering team free of charge.</p>
                           <a className="sidebar-cta-btn phone" href="tel:+443337726247">0333&nbsp;772&nbsp;6247</a>
                        </div>

                        <div className="article-navigation-ol-container">
                           <div className="article-navigation-ol-header">
                              <FaCompass/>&nbsp;&nbsp;Navigation
                           </div>

                           <ol className="article-navigation-ol">
                              {printInternalNavigation()}
                           </ol>
                        </div>
                     </div>
                  </div>
               </div>       
            </div>
         </div>
         <Footer/>
      </React.Fragment>
   )
}

export default GuideTemplate

export const pageQuery = graphql`
   query ContentfulBlogPostBySlug( $slug: String!) {
      contentfulGuide( slug: { eq: $slug }) {
         title
         metaDescription
         updatedAt
         content {
            internal {
               content
            }
         }
         author
         navigation {
            internal {
               content
            }
         }
         article_desc {
            internal {
               content
            }
         }
         image {
            file {
               url
            }
         }
         slug
      }
   }
`